import React, { useState } from "react";
import { Grid, Cell } from "react-foundation";
import {
  Button,
  InfoContainer,
  Container,
  Description,
  Heading,
  ImageContainer,
  Link,
  PurpleLine,
  PressLogoContainer,
  Dot,
  DotContainer,
  ForbesLogo,
  WsjLogo,
  BloombergLogo,
  MsnbcLogo,
  QuotesContainer,
  Quote,
  RightChevronContainer,
  LeftChevronContainer,
  ChevronContainer,
  Spacer,
} from "./WhyUsSection.style";
import BusinessInsider from '../../../../assets/images/logos/business-insider-logo-black.png';
import BloombergImg from '../../../../assets/images/logos/bloomberg-law-logo-black.svg';
import Bloomberg2Img from '../../../../assets/images/logos/bloomberg-logo-black.png';
import WlImg from '../../../../assets/images/logos/westlaw-logo.png';
import WsjImg from '../../../../assets/images/logos/wsj-logo-black.png';
import ForbesImg from '../../../../assets/images/logos/forbes-logo-black.png';
import MsnbImg from '../../../../assets/images/logos/msnb-logo-black.png';
import MaxsImg from '../../../../assets/images/about/max-volsky-and-max-schmidt.png';
import ChevronLeftImg from '../../../../assets/images/icons/chevron-left.svg';
import ChevronRightImg from '../../../../assets/images/icons/chevron-right.svg';

const quotes = [
  {
    quote: "LexShares Raises $100 Million for Litigation Funding ‘Certainty’",
    imagePath: BloombergImg,
    linkPath:
      "https://news.bloomberglaw.com/us-law-week/lexshares-raises-100-million-for-litigation-funding-certainty",
  },
  {
    quote: "The Power Players of the Booming Litigation Finance Industry",
    imagePath: BusinessInsider,
    linkPath:
      "https://www.businessinsider.com/power-players-litigation-funding-finance-burford-parabellum-legal-law-firms-2021-5",
  },
  {
    quote:
      "LexShares Touts 'Dramatic' Growth for Midmarket Litigation Funding Biz",
    imagePath: WlImg,
    linkPath:
      "https://today.westlaw.com/Document/I170e18e0029311eb96129628b195edfb/View/FullText.html?transitionType=SearchItem&contextData=(sc.Default)&firstPage=true",
  },
];

const WhyUsSection = () => {
  const [currentQuoteIndex, setCurrentQuoteIndex] = useState(0);
  return (
    <Container>
      <Grid className="grid-margin-x grid-margin-y">
        <Cell small={12} medium={6}>
          <InfoContainer>
            <Heading>One of the most active funders in the industry</Heading>
            <Description>
              Through a combination of proprietary technology and an experienced
              team, LexShares has become a global leader in litigation finance.
            </Description>
            <ImageContainer>
              <Grid className="grid-margin-x">
                <Cell small={3}>
                  <ForbesLogo>
                    <img src={ForbesImg}/>
                  </ForbesLogo>
                </Cell>
                <Cell small={2}>
                  <WsjLogo>
                    <img src={WsjImg} />
                  </WsjLogo>
                </Cell>
                <Cell small={4}>
                  <BloombergLogo>
                    <img src={Bloomberg2Img}/>
                  </BloombergLogo>
                </Cell>
                <Cell small={3}>
                  <MsnbcLogo>
                    <img src={MsnbImg} />
                  </MsnbcLogo>
                </Cell>
              </Grid>
            </ImageContainer>
            <Button href="/press">View Press</Button>
          </InfoContainer>
        </Cell>
        <Cell small={12} medium={6}>
          <QuotesContainer>
            <Grid>
              <Cell small={1}>
                <LeftChevronContainer>
                  <ChevronContainer
                    onClick={() =>
                      currentQuoteIndex === 0
                        ? setCurrentQuoteIndex(2)
                        : setCurrentQuoteIndex(currentQuoteIndex - 1)
                    }
                  >
                    <img src={ChevronLeftImg} />
                  </ChevronContainer>
                </LeftChevronContainer>
              </Cell>
              <Cell small={10}>
                <Quote>“{quotes[currentQuoteIndex].quote}”</Quote>
                <PurpleLine />
                <PressLogoContainer>
                  <Link
                    target="_blank"
                    href={quotes[currentQuoteIndex].linkPath}
                  >
                    <img src={quotes[currentQuoteIndex].imagePath} />
                  </Link>
                </PressLogoContainer>
                <DotContainer>
                  {[...Array(3)].map((_, index) => (
                    <Dot
                      key={`dot-${index}`}
                      active={index === currentQuoteIndex}
                      onClick={() => setCurrentQuoteIndex(index)}
                    />
                  ))}
                </DotContainer>
              </Cell>
              <Cell small={1}>
                <RightChevronContainer>
                  <ChevronContainer
                    onClick={() =>
                      currentQuoteIndex === 2
                        ? setCurrentQuoteIndex(0)
                        : setCurrentQuoteIndex(currentQuoteIndex + 1)
                    }
                  >
                    <img src={ChevronRightImg} />
                  </ChevronContainer>
                </RightChevronContainer>
              </Cell>
            </Grid>
          </QuotesContainer>
        </Cell>
        <Cell small={12} medium={6}>
          <Spacer height={72} />
          <img src={MaxsImg} />
        </Cell>
        <Cell small={12} medium={6}>
          <InfoContainer>
            <Heading>Speed and professionalism</Heading>
            <Description>
              Our team features many former litigators with deep experience
              evaluating case merits and structuring litigation finance
              investments.
            </Description>
            <Button href="/about">View Team</Button>
          </InfoContainer>
        </Cell>
      </Grid>
    </Container>
  );
};

export default WhyUsSection;
